import React, { useCallback, useEffect, useState } from "react";
import { BrandDetailDocument } from "@tiicker/util/lib/contentful/types";
import styles from "./ManualUploadUI.module.scss";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import TextInput from "components/core/Forms/TextInput/TextInput";
import DropDownSelect from "components/core/Forms/DropDownSelect/DropDownSelect";
import { useForm } from "react-hook-form";
import Button from "components/core/Button/Button";
import ChevronDown from "public/icons/chevron-down.svg";
import {
  CreateOrUpdateUserUpload,
  UserUploadType,
} from "$gql/mutations/general/CreateOrUpdateUserUpload.gen";
import {
  useAuth,
  useFetchUser,
  useMutationBundle,
  useQueryBundle,
} from "source/hooks";
import { GetUsersUploads } from "$gql/queries/general/GetUsersUploads.gen";
import Dropzone from "react-dropzone";
import { ContentfulLinkedAsset } from "components/Contentful/ContentfulLinkedComponent";
import Image from "components/Contentful/Image";

type Props = {
  brand: BrandDetailDocument;
  includes: any;
  closeModal: () => void;
  userId: number;
  removeGoToCompany?: boolean;
};

type ManualUploadForm = {
  quantity: number;
  purchased: string;
  brokerage: string;
};

const ManualUploadUI = (props: Props) => {
  const brandImage = getImageReference(props.brand.heroImage, props.includes);
  const [files, setFiles] = useState<File[] | null>(null);
  const [fileError, setFileError] = useState<string>("");
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const [updateHoldings, setUpdateHoldings] = useState<boolean>(false);

  const auth = useAuth();
  const userBundle = useFetchUser();

  const [createOrUpdateUpload] = useMutationBundle(CreateOrUpdateUserUpload);
  const userUploads = useQueryBundle(GetUsersUploads, {
    variables: {
      tickerSymbol: props.brand.tickerSymbol,
    },
  });

  const { handleSubmit, register, errors } = useForm<ManualUploadForm>();

  useEffect(() => {
    if (
      userUploads.state === "DONE" &&
      userUploads.data.getUsersUploads.length > 0
    ) {
      setUploadSuccessful(true);
    }
  }, [userUploads, userUploads.state]);

  const dropdownOptions = [
    { value: "", label: "Please Select" },
    { value: "week", label: "Within the last week" },
    { value: "month", label: "Within the last month" },
    { value: "quarter", label: "Within the last quarter" },
    { value: "halfYear", label: "Within the last 6 months" },
    { value: "year", label: "A year or more ago" },
  ];

  const uploadFile = async (
    file: File,
    shareQuantity: number,
    lastPurchase: string,
    brokerage: string
  ) => {
    const createResult = await createOrUpdateUpload({
      variables: {
        inputs: {
          fileName: `stock-screenshot/${file.name}`,
          fileType: file.type,
          uploadType: UserUploadType.ManualStockVerification,
          metadataAsString: JSON.stringify({
            quantity: shareQuantity,
            tickerSymbol: props.brand.tickerSymbol,
            lastSharesPurchase: lastPurchase,
            brokerage: brokerage,
          }),
        },
      },
    });

    const upload = createResult.data?.createOrUpdateUserUpload || undefined;

    if (!upload || !upload.signedUploadUrl) {
      console.error("Issue uploading");
      return;
    }

    const result = await fetch(upload.signedUploadUrl, {
      method: "PUT",
      body: file,
    });

    await createOrUpdateUpload({
      variables: {
        inputs: {
          id: upload.id,
          fileName: `stock-screenshot/${file.name}`,
          fileType: file.type,
          uploadType: UserUploadType.ManualStockVerification,
        },
      },
    });
  };

  const onSubmit = useCallback(
    async (data: ManualUploadForm) => {
      if (!files || files.length === 0) {
        setFileError("A file is required.");
        return;
      }

      for (var f of files) {
        await uploadFile(f, data.quantity, data.purchased, data.brokerage);
      }
      setUploadSuccessful(true);
      setUpdateHoldings(false);
    },
    [files]
  );

  const extensions = ["jpg", "jpeg", "png", "pdf"];

  const onFileChange = (fileList: File[]) => {
    if (fileList && fileList.length > 0) {
      const sizeLimit = 20_971_520; // 20MB

      for (const f of fileList) {
        if (f.size > sizeLimit) {
          setFileError("Please upload a file under 20MB");
          return;
        }

        if (!extensions.some((ext) => f.type.includes(ext))) {
          setFileError(
            `Please use one of the following formats: .${extensions.join(
              ", ."
            )}`
          );
          return;
        }
      }

      const newFiles =
        files && files.length > 0
          ? files.concat(
              fileList.filter((x) => !files.some((f) => f.name === x.name))
            )
          : fileList;

      setFiles(newFiles);
    }
  };

  return (
    <div className={styles.container}>
      {(!uploadSuccessful || updateHoldings) && (
        <div className={styles.side}>
          <h3>
            {userUploads.state === "DONE" &&
            userUploads.data.getUsersUploads.length > 0
              ? "Update holdings"
              : "Upload Holdings"}
          </h3>
          <p>
            You are one step closer to claiming your perks! To update your
            holdings, please re-upload a DRS statement or brokerage account
            screenshot clearly showing current holdings. If you haven{"’"}t
            tried already, please attempt to connect your brokerage to TiiCKER
            via <a href="/dashboard">My Dashboard</a> page before proceeding.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              placeholder="EX '100'"
              label="Quantity Held"
              name="quantity"
              wrapperClass={styles.inputWrapper}
              register={register({
                required: "Share Quantity is required.",
              })}
              error={errors.quantity && errors.quantity.message}
            />
            <DropDownSelect
              options={dropdownOptions}
              label="When is the last time you purchased shares?"
              name="purchased"
              wrapperClass={styles.inputWrapper}
              placeholder="Please Select"
              register={register({
                required: "Last Purchase time period is required.",
              })}
              error={errors.purchased && errors.purchased.message}
            />
            <TextInput
              label="Enter your brokerage"
              name="brokerage"
              wrapperClass={styles.inputWrapper}
              register={register({
                required: "Brokerage Name is required.",
              })}
              error={errors.brokerage && errors.brokerage.message}
            />
            {/* <FileInput onChange={onFileChange} files={files} /> */}
            {files && files.length > 0 && (
              <>
                {files.map((f) => (
                  <p className={styles.fileName}>{f.name}</p>
                ))}
              </>
            )}
            <Dropzone onDrop={(acceptedFiles) => onFileChange(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div className={styles.fileInputArea}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className={styles.dragAndDrop}>
                      Drag {"&"} Drop or Click to Upload
                      <ChevronDown className={styles.arrow} />
                    </p>
                    <p className={styles.fileType}>
                      {"["}.{extensions.join(", .")} files only.{"]"}
                    </p>
                    {fileError && (
                      <p className={styles.fileError}>{fileError}</p>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
            <Button submit className={styles.submitButton}>
              Submit
            </Button>
          </form>
        </div>
      )}

      {uploadSuccessful && !updateHoldings && (
        <div className={styles.side}>
          <h3>Thank you!</h3>
          <p>
            You've now updated your verification and will get back to you as
            soon as we can! Please look out for an email in the next few days to
            confirm your verification.
          </p>
          <p className={styles.verificationTimePeriod}>
            Verification must be updated ever 3 months.
          </p>

          <div>
            <Button
              type="primary"
              onClick={() => setUpdateHoldings(true)}
              arrow
              className={styles.updateButton}
            >
              Update Holdings
            </Button>
          </div>
          {props.removeGoToCompany && (
            <p className={styles.closeWindow}>You may now close this window.</p>
          )}

          {!props.removeGoToCompany && (
            <Button
              type="secondary"
              onClick={props.closeModal}
              className={styles.closeButton}
              arrow
            >
              Go to Company
            </Button>
          )}
        </div>
      )}

      <div className={styles.side}>
        <div className={styles.imageContainer}>
          <ContentfulLinkedAsset
            includesList={props.includes}
            component={Image}
            imageOptions={{ fm: "jpg", fl: "progressive" }}
            className={styles.image}
            assetId={props.brand.heroImage.sys.id}
            removeLazy
          />
        </div>
      </div>
    </div>
  );
};

export default ManualUploadUI;
