import { Asset } from "contentful";
import React, { CSSProperties, ReactElement } from "react";
import { toQueryString } from "@tiicker/util/lib/utils";
import { ImageOptions } from "source/contentful/provider";

require("lazysizes");

interface Props {
  imageOptions?: ImageOptions;
  className?: string;
  style?: CSSProperties;
  alt?: string;
  asset: Asset;
  removeLazy?: boolean;
}

export default function Image({
  asset,
  style,
  className,
  alt,
  imageOptions,
  removeLazy,
}: Props): ReactElement {
  if (!asset) {
    return <></>;
  }

  const url = [asset.fields.file.url, toQueryString(imageOptions)].join("?");
  const imageClass = [className, removeLazy ? "" : "lazyload"].join(" ");

  const urlData = removeLazy ? { src: url } : { "data-src": url };

  return (
    <img
      alt={alt || asset.fields.title}
      title={alt || asset.fields.title}
      className={imageClass}
      style={style}
      {...urlData}
    />
  );
}
